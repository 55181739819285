export default {
  title: {
    id: 'product_page_insurance_offers_step_title',
    defaultMessage: 'Protect your {model} and your peace of mind.',
  },
  titleWithoutModel: {
    id: 'product_page_insurance_offers_step_title_without_model',
    defaultMessage: 'Protect your device and your peace of mind.',
  },
  guidance: {
    id: 'product_page_insurance_offers_step_guidance',
    defaultMessage: 'See BackUp protection plan details',
  },
}
