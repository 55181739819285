export default {
  originalPriceDisplay: {
    id: 'product_page_add_to_cart_original_price_display',
    defaultMessage: '{price} new',
  },
  strikedPriceExplanation: {
    id: 'stroked_price_explanation',
    defaultMessage:
      "Crossed out prices may be the manufacturer's suggested retail price, or may be calculated from the average price of the product from different retail distributors.",
  },
  originalPriceDisplayHtml: {
    id: 'product_page_add_to_cart_original_price_display_html',
    defaultMessage: '{price, html} new',
  },
}
