export default {
  categoriesTitle: {
    id: 'buyback_swap_v2_modal_categories_title',
    defaultMessage: 'Sell your old tech',
  },
  continue: {
    id: 'swap_confirmation_continue',
    defaultMessage: 'Continue',
  },
  loadingOfferText: {
    id: 'buyback_swap_loading_offer_text',
    defaultMessage:
      'Finding you the highest offer out of more than 250 professional refurbishers!',
  },
  exit: {
    id: 'buyback_swap_step_exit',
    defaultMessage: 'Exit',
  },
}
