import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

export default {
  resultSeller: {
    id: 'product_page_customization_result_seller',
    defaultMessage: 'Proudly refurbished by {seller, html}',
  },
  resultSellerNew: {
    id: 'product_page_customization_result_seller_sold',
    defaultMessage: 'Sold by {seller, html}',
  },
} as Record<string, I18nDefinition>
