<template>
  <p
    v-if="selectedOffer.merchant"
    class="text-static-default-low text-caption mb-16 flex items-center"
  >
    <FormattedMessage
      class="truncate"
      :definition="
        category !== 'Refurbished'
          ? translations.resultSellerNew
          : translations.resultSeller
      "
      tag="span"
    >
      <template #seller>
        <RevLink
          :to="selectedOffer.merchant.merchantUrl"
          @click="onSellerClick"
        >
          {{ selectedOffer.merchant.company }}
        </RevLink>
      </template>
    </FormattedMessage>

    <Flag
      v-if="shouldDisplaySellerFlag"
      :aria-label="selectedOffer.merchant.country.name"
      class="ml-8 shrink-0"
      :country-code="selectedOffer.merchant.country.code as MarketCountryCode"
    />

    <span v-if="shouldDisplaySellerCountry" class="ml-4 shrink-0">
      ({{ selectedOffer.merchant.country.name }})
    </span>
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import type { GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import translations from './Seller.translations'

const COUNTRIES_WITH_SELLER_FLAG = [MarketCountryCode.US, MarketCountryCode.AU]

const { trackClick } = useTracking()
const marketplace = useMarketplace()

const props = defineProps<{
  selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>
  category: GetProductResponse['type']
}>()

const shouldDisplaySellerFlag = computed(() => {
  return (
    COUNTRIES_WITH_SELLER_FLAG.includes(marketplace.market.countryCode) &&
    !!props.selectedOffer.merchant &&
    !!props.selectedOffer.merchant.country.code
  )
})

const shouldDisplaySellerCountry = computed(() => {
  return (
    !!props.selectedOffer.merchant &&
    !!props.selectedOffer.merchant.country.code &&
    !shouldDisplaySellerFlag.value
  )
})

function onSellerClick() {
  trackClick({
    zone: 'recap',
    name: 'seller',
    value:
      props.selectedOffer.merchant?.country.code ||
      props.selectedOffer.merchant?.country ||
      '',
  })
}
</script>
