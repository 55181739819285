<template>
  <div class="isolate">
    <p class="leading-caption caption line-clamp-3 text-ellipsis">
      {{ review.comment }}
    </p>
    <RevButtonBase
      class="bg-surface-default-low text-static-default-hi caption-link absolute bottom-0 right-0 z-[1] ml-2 w-[max-content] cursor-pointer justify-self-end text-right underline"
      :class="[beforeGradient]"
      @click="scrollTo(review.id)"
    >
      <span class="text-action-default-hi">
        {{ readMoreText }}
      </span>
    </RevButtonBase>
  </div>
</template>

<script setup lang="ts">
import { useReviewsScrollRegistry } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { RevButtonBase } from '@ds/components/ButtonBase'

import type { DisplayedReview } from '~/scopes/reviews/reviews-display/models/reviews'

import translations from './TopOfThePageReviews.translations'

const { review } = defineProps<{
  review: DisplayedReview
}>()

const i18n = useI18n()
const tracking = useTracking()

const registry = useReviewsScrollRegistry()

const readMoreText = i18n(translations.readMore)

const scrollTo = (targetId: string) => {
  registry.scrollTo(targetId)

  tracking.trackClick({
    name: `review_${targetId}`,
    zone: 'trust_assurance_banner',
    page_type: 'product',
  })
}

const beforeGradient = tw`before:content-[''] before:absolute before:bottom-0 before:right-0 before:z-[-1] before:h-[1rem] before:w-[140%] before:from-bg-surface-default-low before:to-transparent before:to-100% before:from-80% before:bg-gradient-to-l`
</script>
