<template>
  <span v-if="shouldDisplayVatIncluded" class="text-static-default-low caption">
    {{ i18n(translations.productVatIncluded) }}
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { COUNTRIES_WITH_VAT_PRICE_LABEL } from '../../Price.constants'

import translations from './VatIncluded.translations'

const i18n = useI18n()

const {
  features: { vatIncluded },
  market: { countryCode },
} = useMarketplace()

const shouldDisplayVatIncluded = computed(() => {
  return vatIncluded && !COUNTRIES_WITH_VAT_PRICE_LABEL.includes(countryCode)
})
</script>
