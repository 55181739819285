export default {
  beforeTradeIn: {
    id: 'product_price_wrapper_trade_in',
    defaultMessage: 'before trade-in',
  },
  withMobilePlan: {
    id: 'product_price_label_with_mobile_plan',
    defaultMessage: 'with mobile plan',
  },
  withTradeIn: {
    id: 'product_price_label_with_trade_in',
    defaultMessage: 'after trade-in',
  },
  withTradeInAndMobilePlan: {
    id: 'product_price_label_with_mobile_plan_and_trade_in',
    defaultMessage: 'with plan and trade-in',
  },
  dueToday: {
    id: 'product_price_label_due_today',
    defaultMessage: '({price} due today)',
  },
  vatIncluded: {
    id: 'product_price_label_vat_included',
    defaultMessage: 'tax included',
  },
}
