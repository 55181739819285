<template>
  <div class="flex items-center justify-start gap-0">
    <RevButtonBase
      class="flex w-full items-center justify-start gap-12 self-stretch pe-16 md:pe-24"
      @click="deployVerifiedRefurbishedModal"
    >
      <div
        class="rounded-md relative h-48 !w-48 bg-[radial-gradient(302.53%_151.13%_at_94.79%_4.17%,#E2F77E_0%,#A366E8_66.22%,#2B0F3A_100%),radial-gradient(268.35%_134.06%_at_94.79%_5.21%,#E2F77E_0%,#A366E8_66.22%,#2B0F3A_100%)]"
      >
        <IconListViewChecks
          class="absolute bottom-0 left-0 right-0 top-0 m-auto"
          size="32"
        />
      </div>
      <p class="text-action-default-hi text-start">
        <span class="leading-body-2-bold flex items-center gap-4">
          <span
            class="text-body-2 leading-body-2 font-weight-body-2-bold font-body-2-bold whitespace-nowrap"
            >{{ title }}</span
          >
          <IconInfo size="16" />
        </span>
        <span
          class="heading-1-italic mb-2 block text-[1.18rem] leading-[normal]"
        >
          {{ subtitle }}
        </span>
      </p>
    </RevButtonBase>
    <RevDivider class="h-auto self-stretch" variant="vertical" />
    <VerifiedRefurbishedModal />
  </div>
</template>

<script setup lang="ts">
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevDivider } from '@ds/components/Divider'
import { openModal } from '@ds/components/ModalBase'
import { IconInfo } from '@ds/icons/IconInfo'
import { IconListViewChecks } from '@ds/icons/IconListViewChecks'

import { MODAL_NAMES } from '~/scopes/product/constants'

import VerifiedRefurbishedModal from '../../ReassuranceItems/components/VerifiedRefurbishedModal/VerifiedRefurbishedModal.vue'

defineProps<{
  title: string
  subtitle: string
}>()

function deployVerifiedRefurbishedModal() {
  openModal(MODAL_NAMES.VERIFIED_REFURBISHED)
}
</script>
