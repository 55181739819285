<template>
  <div>
    <StepLayout :without-left-template>
      <template #left>
        <StepIllustration src="/img/insurances/insurances-step.svg" />
      </template>
      <template #right>
        <h3 class="heading-1 mb-16">
          <template v-if="model">
            {{ i18n(translations.title, { model }) }}
          </template>
          <template v-else>{{ i18n(translations.titleWithoutModel) }}</template>
        </h3>

        <CardGuidance
          clickable
          :icon="IconQuality"
          :label="i18n(translations.guidance)"
          :modalName="MODAL_NAMES.BACK_UP_INSURANCE_MODAL"
        />
      </template>
    </StepLayout>

    <InsuranceModal :name="MODAL_NAMES.BACK_UP_INSURANCE_MODAL" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconQuality } from '@ds/icons/IconQuality'

import InsuranceModal from '~/scopes/insurance/components/InsuranceOffers/components/InsuranceModal/InsuranceModal.vue'
import { MODAL_NAMES } from '~/scopes/insurance/constants'

import CardGuidance from '../CardGuidance/CardGuidance.vue'
import StepIllustration from '../StepIllustration/StepIllustration.vue'
import StepLayout from '../StepLayout/StepLayout.vue'

import translations from './InsuranceOffersStep.translations'

const i18n = useI18n()

defineProps<{ model?: string; withoutLeftTemplate?: boolean }>()
</script>
