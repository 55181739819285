<template>
  <StepLayout
    data-test="step-swap"
    :is-focused
    stretch
    :tracking="{
      trackingTags: {
        tagDisplayed: 'no_tag',
        tagSelected: false,
      },
      trackingId: 'swap',
      trackingModel: model,
      trackingCategory: category,
    }"
    :without-left-template
  >
    <template #left>
      <RevIllustration
        alt=""
        class="block min-w-[337px] max-w-[498px] rounded-[32px]"
        :height="498"
        src="/img/product/funnel/step-trade-in-2.jpg"
        :width="498"
      />
    </template>
    <template #right>
      <h3 class="heading-1 mb-16">
        {{ i18n(translations.titleStep) }}
      </h3>
      <SwapOffer
        v-if="hasBuybackOfferAvailable"
        :buybackOffer
        :model
        @default-item-selected="handleDefaultItemSelected"
        @item-selected="handleItemSelected"
        @remove="deleteBuybackOffer"
      />
      <template v-else>
        <StepQuestions
          v-if="selectedModel"
          datalayerCategory=""
          :initialPayload="selectedModel"
          zone="product_page"
          @default-item-selected="handleDefaultItemSelected"
          @exit="handleExit"
          @item-selected="handleItemSelected"
          @on-finish="handleOnFinish"
          @save-no-offer="saveHasNoOffer"
          @save-offer="handleSaveOffer"
        />
        <SwapIntro
          v-else
          @default-item-selected="handleDefaultItemSelected"
          @item-selected="handleItemSelected"
          @no-picker-selected="handleNoPickerSelected"
        />
      </template>
    </template>
  </StepLayout>
</template>

<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue'
import { type LocationQuery } from 'vue-router'

import type { Price } from '@backmarket/http-api'
import type { GetOfferV1Response } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'
import { RevIllustration } from '@ds/components/Illustration'

import type { BuybackProduct } from '~/scopes/buyback/composables/useBuybackSearch'
import { useSwapModalStore } from '~/scopes/buyback/swap/stores/useSwapModalStore'
import StepLayout from '~/scopes/product/components/CustomizationFunnel/components/StepLayout/StepLayout.vue'

import StepQuestions from '../StepQuestions/StepQuestions.vue'
import SwapIntro from '../SwapIntro/SwapIntro.vue'
import SwapOffer from '../SwapOffer/SwapOffer.vue'

import translations from './EmbeddedStepSwap.translations'

const props = defineProps<{
  isFocused: boolean
  withoutLeftTemplate?: boolean
  price?: Price
  model?: string
  category?: string
}>()

const {
  hasBuybackOfferAvailable,
  resetHasNoOffer,
  deleteBuybackOffer,
  buybackOffer,
  saveHasNoOffer,
  saveBuybackOffer,
} = useBuybackOffer()
const i18n = useI18n()
const { setShowAddToCartModal } = useSwapModalStore()

const selectedModel = ref<LocationQuery | null>(null)

function handleItemSelected(item: BuybackProduct) {
  resetHasNoOffer()
  selectedModel.value = removeEmptyValuesInObject({
    category: item.category,
    brand: item.brand,
    model: item.model,
    model_family: item.productLine,
  })
}

function handleDefaultItemSelected() {
  resetHasNoOffer()
  selectedModel.value = {}
}

function handleOnFinish() {
  selectedModel.value = null
}

function handleExit() {
  selectedModel.value = null
}

function handleNoPickerSelected() {
  setShowAddToCartModal(false)
}

function handleSaveOffer(offer: GetOfferV1Response, form: LocationQuery) {
  saveBuybackOffer(offer, form)
  setShowAddToCartModal(false)
}

watch(
  () => props.isFocused,
  (isFocused) => {
    if (!isFocused) {
      const focusedElement = document.activeElement as HTMLElement
      focusedElement?.blur()
    }
  },
)

onUnmounted(() => {
  resetHasNoOffer()
})
</script>
