import { createError, useRoute } from '#imports'
import { type Ref, computed } from 'vue'

import {
  type HttpRequestUrlParameters,
  productReviewsAPI,
} from '@backmarket/http-api'
import { getSeoTags } from '@backmarket/http-api/src/api-specs-acquisition/link-booster/tags'
import { getBreadcrumb } from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import { getProductEcoBlock } from '@backmarket/http-api/src/api-specs-navigation-experience/product/eco-block'
import {
  type GetPickersResponse,
  getPickers,
  getServicesPickers,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { getProduct } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { getTechnicalSpecifications } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'
import { filterSelectedOfferABTestedPaymentMethods } from '@backmarket/nuxt-layer-payment/methods/helpers/filterABTestedPaymentMethods'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isBrowser } from '@backmarket/utils/env/isBrowser'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import { useMasterPP } from '~/scopes/master-pp/composables/useMasterPP'
import {
  useESimExperiment,
  useProvideESimExperiment,
} from '~/scopes/product/composables/useESimExperiment'
import { useGetOrderByParamValue } from '~/scopes/reviews/reviews-display/composables/useGetOrderByParamValue'
import { MAX_REVIEWS_DISPLAYED_IN_LIST } from '~/scopes/reviews/reviews-display/constants'
import { transformReviewsResponse } from '~/scopes/reviews/reviews-display/utils/transformReviewsResponse'

import { filterOutOfStockPickers } from '../utils/filterOutOfStockPickers'

export async function useProductRequests(
  productId: string,
  grade: Ref<number | null>,
  offerType: Ref<number | null>,
  mobilePlan: Ref<string | null>,
  withNoGrade: boolean,
) {
  /**
   * Most endpoints on the product page are "optional". If the call fails, we just don't display the associated
   * features (i.e.: reviews). We want to override the default 20s timeout on SSR to avoid huge latency spikes
   * that can come from any endpoint to impact the product page latency
   */
  const SSR_TIMEOUT_FOR_OPTIONAL_ENDPOINT = 5000
  const optionalEndpointOptions = !isBrowser()
    ? { timeout: SSR_TIMEOUT_FOR_OPTIONAL_ENDPOINT }
    : {}

  const route = useRoute()
  const experiments = useExperiments()
  const locale = useI18nLocale()
  const reviewsOrderByQueryParam = useGetOrderByParamValue()

  const { plpIdInUrl } = useMasterPP()

  const requestParams = { pathParams: { productId } }

  const { getRating, getReviews } = productReviewsAPI

  const pickersQueryParams = computed(() => {
    const values = {
      grade: grade.value ? String(grade.value) : null,
      special_offer_type: offerType.value ? String(offerType.value) : null,
      premium_grade: true,
      with_grade_offers:
        experiments['experiment.ppNoGrade'] === 'withNoGrade' || withNoGrade,
      cheapest_by_grade: !route.hash.includes('variantClicked'),
    }

    return removeEmptyValuesInObject(values) as HttpRequestUrlParameters
  })

  const servicePickersQueryParams = computed(() => {
    return removeEmptyValuesInObject({
      mobile_plan_offer_id: mobilePlan.value ? String(mobilePlan.value) : null,
    })
  })

  const reviewsQueryParams = computed(() => ({
    ...route.query,
    page_size: MAX_REVIEWS_DISPLAYED_IN_LIST,
    translation_locale: locale,
    ...insertIf(!!reviewsOrderByQueryParam, {
      order_by: reviewsOrderByQueryParam,
    }),
  }))

  // Required calls
  const getProductPromise = useHttpFetch(getProduct, {
    pathParams: { productId },
    queryParams: {
      withVisiblePartnership: true,
    },
    transform: (product) => {
      // Override the title with the model for Master PP PoC
      if (plpIdInUrl.value) {
        return {
          ...product,
          titles: {
            default: String(product.model),
            raw: String(product.model),
          },
        }
      }

      return product
    },
  })

  useProvideESimExperiment(getProductPromise.data)

  const { transformPickersESimExperiment } = useESimExperiment()

  const [
    { data: productResponse, error: productError },
    {
      data: servicesPickersResponse,
      pending: isServicesPickersResponsePending,
      refresh: callServicesPickers,
    },
    { data: breadcrumbResponse },
    { data: technicalSpecificationsResponse },
    { data: tagsResponse },
    { data: ecoBlockResponse },
    {
      data: getPickersResponse,
      pending: isPickersResponsePending,
      error: pickersError,
    },
    { data: ratesResponse },
    { data: reviewsResponse, pending: isReviewsResponsePending },
  ] = await Promise.all([
    getProductPromise,
    useHttpFetch(getServicesPickers, {
      pathParams: { productId },
      queryParams: servicePickersQueryParams,
      // Services pickers currently returns mobile plan & trade-in pickers. They're only called
      // client-side as they rely on user's session (especially the trade-in one, that checks the user's cart),
      // meaning that we can't cache their response.
      server: false,
      // Use immediate:false and watch:false to activate the kill switch on this endpoint.
      lazy: true,
    }),
    // Optional calls
    useHttpFetch(getBreadcrumb, {
      ...requestParams,
      ...optionalEndpointOptions,
    }),
    useHttpFetch(getTechnicalSpecifications, {
      ...requestParams,
      ...optionalEndpointOptions,
      lazy: true,
    }),
    useHttpFetch(getSeoTags, {
      queryParams: plpIdInUrl.value
        ? { model: 'landing', pk: plpIdInUrl.value }
        : { model: 'product', pk: productId },
      ...optionalEndpointOptions,
      lazy: true,
    }),
    useHttpFetch(getProductEcoBlock, {
      ...requestParams,
      ...optionalEndpointOptions,
    }),
    useHttpFetch(getPickers, {
      pathParams: { productId },
      queryParams: pickersQueryParams,
      transform: (response: GetPickersResponse) =>
        filterOutOfStockPickers(
          response,
          experiments['experiment.ppHideOutOfStockPickers'],
        ),
    }),
    useHttpFetch(getRating, {
      pathParams: { uuid: productId },
      ...optionalEndpointOptions,
    }),
    useHttpFetch(getReviews, {
      headers: { 'BM-Page-Name': route.name },
      pathParams: { uuid: productId },
      queryParams: reviewsQueryParams,
      transform: ({ results }) => ({
        results: transformReviewsResponse(results),
      }),
      ...optionalEndpointOptions,
      lazy: true,
    }),
  ])

  // Those 2 endpoints are the only one required to display a viable product page, allowing the user to add a product to its cart,
  // so that's the only 2 that should trigger an error page (404 or 500)
  if (productError.value || pickersError.value) {
    throw createError({
      statusCode:
        productError.value?.statusCode || pickersError.value?.statusCode,
      statusMessage: '[PRODUCT] not found',
      // https://nuxt.com/docs/getting-started/error-handling#createerror
      // If you throw an error created with createError:
      // - on server-side, it will trigger a full-screen error page which you can clear with clearError.
      // - on client-side, it will throw a non-fatal error for you to handle. If you need to trigger a full-screen error page, then you can do this by setting fatal: true.
      fatal: true,
    })
  }

  const pickersResponse = computed(() => {
    if (getPickersResponse.value) {
      return transformPickersESimExperiment(getPickersResponse.value)
    }

    return null
  })

  const isOutOfStock = computed(() => {
    if (!pickersResponse.value && !isPickersResponsePending.value) {
      return true
    }

    return pickersResponse.value?.isOutOfStock ?? false
  })

  const selectedOffer = computed(() => {
    if (!pickersResponse.value?.selectedOffer || isOutOfStock.value) {
      return null
    }

    return filterSelectedOfferABTestedPaymentMethods(
      experiments,
      pickersResponse.value?.selectedOffer,
    )
  })

  return {
    productResponse,
    breadcrumbResponse,
    technicalSpecificationsResponse,
    tagsResponse,
    ecoBlockResponse,
    pickersResponse,
    servicesPickersResponse,
    isPickersResponsePending,
    isServicesPickersResponsePending,
    ratesResponse,
    reviewsResponse,
    isReviewsResponsePending,
    isOutOfStock,
    selectedOffer,
    callServicesPickers,
  }
}
