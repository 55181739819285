export default {
  verified: {
    id: 'product_page_trust_assurance_banner_verified',
    defaultMessage: 'Verified',
  },
  refurbished: {
    id: 'product_page_trust_assurance_banner_refurbished',
    defaultMessage: 'Refurbished',
  },
}
