<template>
  <div v-if="shouldDisplayGallery">
    <div class="flex justify-center" data-test="carousel">
      <RevCarousel
        :alternative-button-label="i18n(translations.alternativeController)"
        :alternative-next="i18n(translations.alternativeNext)"
        :alternative-previous="i18n(translations.alternativePrevious)"
        class="mt-12 md:mt-32 md:grow"
        current-index-id="gallery-carousel"
        :thumbnails
        :tracking="{ name: 'product' }"
        @navigation-click="() => trackCarousel('thumbnail_carousel')"
        @next-button-click="() => trackCarousel('right_arrow')"
        @prev-button-click="() => trackCarousel('left_arrow')"
        @swipe="() => trackCarousel('swipe')"
      >
        <div
          v-for="(image, index) in carouselImages"
          :key="image.src"
          class="relative"
        >
          <RevIllustration
            v-bind="{
              ...(index === 0 && {
                fetchpriority: 'high',
              }),
            }"
            :alt="image.alt"
            class="rounded-lg block !h-[66.7vw] max-h-full w-auto cursor-pointer md:!h-auto md:w-full lg:w-[29.125rem]"
            :height="976"
            :loading="shouldLoadEagerly(index)"
            sizes="(max-width: 768px) 100vw, 466px"
            :src="image.src"
            :width="976"
            @click="handleCarouselClick"
          />
          <div class="absolute bottom-0 left-0 right-0">
            <div
              v-if="image.description"
              class="rounded-b-lg absolute bottom-0 left-0 right-0 top-0"
              :class="$style.gradient"
            />
            <div
              v-if="image.description"
              class="text-action-default-hi mood-inverse body-1-bold relative z-[2] flex items-center px-24 py-24"
            >
              <component
                :is="image.description.icon"
                class="mr-12"
                height="32"
                width="32"
              />
              <p>
                {{ image.description.text }}
              </p>
            </div>
          </div>
        </div>
      </RevCarousel>
    </div>
    <RevMediaViewer
      v-if="allowMediaViewer"
      :alternative-button-label="i18n(translations.alternativeController)"
      :alternative-next="i18n(translations.alternativeNext)"
      :alternative-previous="i18n(translations.alternativePrevious)"
      :close-button-label="i18n(translations.alternativeClose)"
      current-index-id="gallery-media-viewer"
      data-test="product-page-media-viewer"
      :name="MODAL_NAMES.MEDIA_VIEWER"
      :thumbnails
    >
      <template #default="{ imgClassNames = '' } = {}">
        <RevIllustration
          v-for="(image, index) in carouselImages"
          :key="image.alt"
          :alt="image.alt"
          :class="imgClassNames"
          :height="1200"
          :loading="shouldLoadEagerly(index)"
          :src="image.src"
          :width="1200"
        />
      </template>
    </RevMediaViewer>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCarousel } from '@ds/components/Carousel'
import { RevIllustration } from '@ds/components/Illustration'
import { RevMediaViewer } from '@ds/components/MediaViewer'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './Gallery.translations'
import { type ReassuranceImagesType, getReassuranceImages } from './utils'

const props = withDefaults(
  defineProps<{
    images: GetProductResponse['images']
    allowMediaViewer?: boolean
    allowPartnersImages?: boolean
    allowReassuranceImages?: boolean
    trackingProductModel?: string
    trackingZone: string
    product: GetProductResponse
  }>(),
  {
    allowMediaViewer: true,
    allowPartnersImages: true,
    allowReassuranceImages: false,
    trackingProductModel: '',
  },
)

const i18n = useI18n()
const { trackClick } = useTracking()
const experiments = useExperiments()

const carouselImages = computed(() => {
  const reassuranceImgs = getReassuranceImages(
    experiments['experiment.ppReassuranceImages'],
    props.product.tracking.categoryId,
    props.product.type,
    i18n,
  )

  return props.images
    .filter((image) => props.allowPartnersImages || image.source !== 'PARTNER')
    .map((image) => ({
      src: image.url,
      alt: image.description,
    }))
    .reduce((acc, image, index) => {
      acc.push(image)

      if (props.allowReassuranceImages && reassuranceImgs[index]) {
        acc.push(reassuranceImgs[index])
      }

      return acc
    }, [] as ReassuranceImagesType)
})

const thumbnails = computed(() => {
  return carouselImages.value.map((image) => ({
    ...image,
    width: 100,
    height: 100,
  }))
})

const shouldDisplayGallery = computed(() => props.images.length > 0)

function shouldLoadEagerly(index: number) {
  return index === 0 ? 'eager' : 'lazy'
}

function trackCarousel(name: string): void {
  trackClick({
    name,
    zone: props.trackingZone,
    product_model: props.trackingProductModel ?? '',
  })
}

function handleCarouselClick(): void {
  if (props.allowMediaViewer) {
    trackCarousel('photo_carousel')
    openModal(MODAL_NAMES.MEDIA_VIEWER)
  }
}
</script>

<style module>
.gradient {
  mask: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(0, 0, 0, 0.5) 32%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0.9) 69.5%,
    rgba(0, 0, 0, 1) 100%
  );
  backdrop-filter: blur(16px);
  background: #0a174180;
  background-blend-mode: multiply;
}
</style>
