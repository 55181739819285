<template>
  <RevTooltip
    v-if="shouldDisplayOriginalPrice && priceWhenNew"
    class="caption z-[1]"
    position="bottom"
  >
    <template #trigger="{ hide, show }">
      <button
        class="text-static-default-low cursor-pointer whitespace-nowrap"
        @click="show"
        @focusin="show"
        @focusout="hide"
        @mouseenter="show"
        @mouseleave="hide"
      >
        <FormattedMessage
          :class="{
            'body-2': !shouldDisplayStrikedPrice,
            'body-2-striked': shouldDisplayStrikedPrice,
          }"
          :definition="translations.originalPriceDisplayHtml"
        >
          <template #price>
            <span class="body-2-striked">
              {{ i18n.price(priceWhenNew) }}
            </span>
          </template>
        </FormattedMessage>
      </button>
    </template>
    {{ i18n(translations.strikedPriceExplanation) }}
  </RevTooltip>
</template>

<script lang="ts" setup>
/**
 * Displays the original price of the device, stricked, with a tooltip explaining how this is computed.
 *
 * We have a mix of frontend AND backend conditions to display it, and we should change this someday.
 * - We sometimes don't have the original price value
 * - We don't display it in some countries (i.e.: Japan)
 * - Backend sends us, in best_offers endpoint, a boolean to let us know if the difference between
 *   the original price and the offer is big enough to display it
 *   @see https://github.com/BackMarket/badoom/blob/main/product_page/listing/views/v3/best_offers.py#L321-L346
 */
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevTooltip } from '@ds/components/Tooltip'

import { COUNTRIES_WITHOUT_STRIKED_PRICE_NEW_LABEL } from '../../Price.constants'

import translations from './OriginalPrice.translations'

const props = defineProps<{
  isDiscountReassuranceDisplayed: boolean
  priceWhenNew?: Price
}>()

const i18n = useI18n()
const {
  market: { countryCode },
} = useMarketplace()

const shouldDisplayStrikedPrice = computed(() => {
  return !COUNTRIES_WITHOUT_STRIKED_PRICE_NEW_LABEL.includes(countryCode)
})

const shouldDisplayOriginalPrice = computed(() => {
  return props.isDiscountReassuranceDisplayed && !isEmpty(props.priceWhenNew)
})
</script>
