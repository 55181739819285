<template>
  <div v-if="label" class="flex flex-wrap gap-x-4">
    <span class="body-2-bold whitespace-nowrap">
      {{ label }}
    </span>
    <span
      v-if="shouldShowDueToday && priceDueToday"
      class="text-static-default-low body-2"
    >
      {{ i18n(translations.dueToday, { price: i18n.price(priceDueToday) }) }}
    </span>
  </div>
</template>

<script lang="ts" setup>
/**
 * For now this component only displays "before trade-in" mention, but in a very near future it'll also
 * display "after trade-in" with due today mention, or "with mobile plan", etc.
 */
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import type { GetOfferV1Response } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { useUrlParams } from '~/scopes/product/composables/useUrlParams'

import {
  COUNTRIES_WITH_TRADE_IN_PRICE_LABEL,
  COUNTRIES_WITH_VAT_PRICE_LABEL,
} from '../../Price.constants'

import translations from './PriceLabel.translations'

const props = defineProps<{
  buybackOffer: GetOfferV1Response | null
  priceDueToday?: Price | null
}>()

const i18n = useI18n()
const { mobilePlan } = useUrlParams()

const {
  market: { countryCode },
} = useMarketplace()

const isSwapActive = computed(() => {
  return props.buybackOffer
})

const shouldShowDueToday = computed(() => {
  return isSwapActive.value && props.priceDueToday
})

const label = computed(() => {
  if (mobilePlan.value && isSwapActive.value) {
    return i18n(translations.withTradeInAndMobilePlan)
  }

  if (mobilePlan.value) {
    return i18n(translations.withMobilePlan)
  }

  if (isSwapActive.value) {
    return i18n(translations.withTradeIn)
  }

  if (COUNTRIES_WITH_TRADE_IN_PRICE_LABEL.includes(countryCode)) {
    return i18n(translations.beforeTradeIn)
  }

  if (COUNTRIES_WITH_VAT_PRICE_LABEL.includes(countryCode)) {
    return i18n(translations.vatIncluded)
  }

  return null
})
</script>
