<template>
  <section
    class="bg-surface-default-low border-static-info-mid rounded-lg grid grid-cols-2 gap-16 border py-8 pe-8 ps-8 md:grid-cols-[1fr_1fr_minmax(auto,40%)] md:gap-24 md:pe-24"
  >
    <VerifiedRefurbishedButton
      :subtitle="i18n(translations.refurbished)"
      :title="i18n(translations.verified)"
    />

    <div class="flex h-auto w-full items-center">
      <slot />
    </div>
    <div class="hidden md:block">
      <TopOfThePageReviewsSkeleton v-if="reviews.isPending" />
      <TopOfThePageReviews v-else :reviews-list="reviews.list" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import type { DisplayedReview } from '~/scopes/reviews/reviews-display/models/reviews'

import translations from './TrustAssuranceBanner.translations'
import TopOfThePageReviews from './components/TopOfThePageReviews.vue'
import TopOfThePageReviewsSkeleton from './components/TopOfThePageReviewsSkeleton.vue'
import VerifiedRefurbishedButton from './components/VerifiedRefurbishedButton.vue'

const { reviews } = defineProps<{
  reviews: {
    isPending: boolean
    list: DisplayedReview[]
  }
}>()

const i18n = useI18n()
</script>
